
import { defineComponent } from "vue";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";
declare let window: any;

export default defineComponent({
  name: "update-required",
  setup() {
    const getAppLink = () => {
      const appLink =
        window.cordova && window.cordova.platformId === "android"
          ? "https://play.google.com/store/apps/details?id=fr.agri.best"
          : "https://apps.apple.com/us/app/agribest/id1664888828";
      openLinkAgriBest(appLink);
    };
    return {
      getAppLink,
    };
  },
});
